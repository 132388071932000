import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SEO } from '../components/primitives';
import Positions from '../components/_career/Positions';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Career = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Jobs at SDAA" description="Come join us. We have positions available for various roles." />
      <Positions />
    </motion.article>
  );
};

export default Career;

Career.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

Career.defaultProps = {
  //
};
