import React, { useState, useContext, useEffect } from 'react';
import { StaticQuery, useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import Loadable from 'react-loadable';
import ReactMarkdown from 'react-markdown';
import { Container, Block, Loading, MarkdownStyles } from '../primitives';

const QUERY = graphql`
  query {
    allSanityJobPosting {
      edges {
        node {
          jobTitle
          role
          postDate
          keySkills
          jobDescription
          id
          experience
        }
      }
    }
  }
`;

const Section = styled.section`
  background: #edf2f7;
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  span {
    padding: 4px 8px;
    background: #0e4673;
    margin: 0 4px 0 0;
    color: #ffffff;
  }
  em {
    font-weight: 400;
    color: #2d3748;
  }
  hr {
    border-bottom: 1px #bee3f8 solid;
    margin: 3rem 0 0 0;
  }
`;

const JobWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Positions = () => {
  //
  const {
    allSanityJobPosting: { edges: jobs },
  } = useStaticQuery(QUERY);

  return (
    <Section>
      <Container>
        <Block>
          {jobs.map(job => (
            <JobWrapper key={job.node.id}>
              <h2>{job.node.jobTitle}</h2>
              <br />
              Date: <em>{job.node.postDate}</em>
              <br />
              Role: <em>{job.node.role}</em>
              <br />
              Experience: <em>{job.node.experience}</em>
              <br />
              <br />
              Key Skills:
              <br />
              <div style={{ margin: '0.5rem 0 2rem 0' }}>
                {job.node.keySkills.map(skill => (
                  <span key={skill}>{skill}</span>
                ))}
              </div>
              <MarkdownStyles>
                <ReactMarkdown source={job.node.jobDescription} />
              </MarkdownStyles>
              <hr />
            </JobWrapper>
          ))}
        </Block>
      </Container>
    </Section>
  );
};

export default Positions;

Positions.propTypes = {
  //
};

Positions.defaultProps = {
  //
};
